<template>
  <v-dialog v-model="show" width="500" persistent>
    <v-card>
      <v-card-text class="dialog_message">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          text
          :loading="loading"
          color="secondary"
          class="ma-2 white--text"
          @click="confirmationAction"
        >
          {{ confirmationButtonText }}
        </v-btn>
        <v-btn color="primary" text class="ma-2" @click="dismissAction">
          {{ dismissButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirmation-dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    message: { type: String },
    title: { type: String },
    confirmationAction: { type: Function },
    confirmationButtonText: {
      type: String,
      default: 'Confirmar'
    },
    dismissAction: { type: Function },
    dismissButtonText: {
      type: String,
      default: 'Cancelar'
    }
  },
  computed: {},
  data() {
    return {
      createCategoryForm: true,
      category_value: '',
      rules: {
        required: (value) =>
          (!!value && value.trim().length > 0) || value === null || 'Required.'
      }
    };
  },
  methods: {
    confirm() {
      this.confirmationAction();
    }
  }
};
</script>

<style scoped>
.btn_red {
  border-radius: 8px;
  height: 40px !important;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  background: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  background-size: 200% auto;
}
.btn_red:hover {
  background-position: right center;
}
::v-deep .v-dialog {
  border-radius: 15px;
}
.v-dialog .theme--light.v-sheet {
  background-color: #fff !important;
}
.v-dialog .v-card {
  padding-top: 20px;
}
.dialog_message {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
}
.v-card__actions {
  padding: 10px 0 30px;
  justify-content: center;
}
</style>
