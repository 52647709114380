<template>
  <div id="app">
    <v-app class="transparent_bg">
      <div class="list-table">
        <v-container grid-list-xl>
          <v-snackbar v-model="showSnackbar" :timeout="5000">
            {{ snackbarText }}
            <v-btn color="primary" text @click="showSnackbar = false">
              Close
            </v-btn>
          </v-snackbar>

          <v-layout row wrap>
            <v-flex lg12>
              <v-card>
                <v-toolbar card color="white">
                  <v-text-field
                    flat
                    solo
                    v-model="search"
                    prepend-icon="search"
                    placeholder="Filtrar"
                    hide-details
                    class="hidden-sm-and-down"
                  ></v-text-field>

                  <v-btn dark color="primary" small @click="_createCompany()">
                    Criar Empresa
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-data-table
                    :headers="complex.headers"
                    :search="search"
                    :items="complex.items"
                    pagination.sync="query"
                    :rows-per-page-items="[10, 25, 50, 100]"
                    class="elevation-0"
                    item-key="_id"
                    @update:pagination="_nextPage"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                      class="justify-center"
                    >
                      <td>{{ props.item.name }}</td>
                      <td class="text-xs-right">
                        <v-btn
                          fab
                          dark
                          color="primary"
                          small
                          @click="_editCompany(props.item)"
                        >
                          <v-icon>edit</v-icon>
                        </v-btn>
                        <v-btn
                          fab
                          dark
                          color="primary"
                          small
                          @click="_managePeople(props.item)"
                        >
                          <v-icon>people</v-icon>
                        </v-btn>

                        <v-btn
                          fab
                          dark
                          color="primary"
                          small
                          @click="_callDialogConfirmation(props.item)"
                        >
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </td>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-app>
    <confirmation-dialog
      :show.sync="showDeleteConfirmation"
      :confirmationAction="_callDeleteCompany"
      :dismissAction="() => (this.showDeleteConfirmation = false)"
      :loading="loading"
      message="Tem certeza que deseja excluir esta empresa?"
    >
    </confirmation-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog';

export default {
  components: { ConfirmationDialog },
  data() {
    return {
      showSnackbar: false,
      loading: false,
      showDeleteConfirmation: false,
      snackbarText: '',
      companyToDelete: null,
      currentUser: null,
      query: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'name',
        totalItems: 0
      },
      search: '',
      complex: {
        headers: [
          {
            text: 'Nome',
            value: 'name'
          },
          {
            text: '',
            value: '',
            sortable: false
          }
        ],
        items: []
      }
    };
  },
  computed: {
    ...mapGetters(['getCompanies', 'getUser'])
  },

  async mounted() {
    this.complex.items = [];
    this.currentUser = this.getUser;
    try {
      await this._listCompanies(this.query);
    } catch (e) {
      console.log(e);
    }
    this.complex.items = this.getCompanies;
  },
  methods: {
    ...mapActions({
      _listCompanies: 'listCompanies',
      _deleteCompany: 'deleteCompany'
    }),

    _searchProjects(query) {
      console.log(query);
    },

    async _nextPage(query) {
      await this._listCompanies(query);
      this.complex.items = this.getCompanies;
    },

    _managePeople(company) {
      this.$router.push(`/company/${company._id}/people`);
    },

    _createCompany() {
      this.$router.push(`/company/create`);
    },

    _editCompany(company) {
      this.$router.push(`/company/${company._id}`);
    },

    async _callDeleteCompany() {
      this.loading = true;
      try {
        await this._deleteCompany(this.companyToDelete);
        await this._listCompanies(this.query);
        this.complex.items = this.getCompanies;
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.showDeleteConfirmation = false;
    },

    _callDialogConfirmation(company) {
      this.showDeleteConfirmation = true;
      this.companyToDelete = company;
    }
  }
};
</script>

<style lang="css" scoped></style>
